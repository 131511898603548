<template>
    <div>
        <counter></counter>
    </div>
</template>

<script>
import counter from "@/views/partials/tvd/counter.vue";
import { mapActions } from 'vuex';
export default {
    mounted(){
        this.getData().then( () =>{

        });
    },
    methods : {
        ...mapActions('tvd/',[
            'getData'
        ]),
    },
    components : {
        counter,
    }
}

</script>